import React, { useContext, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { PageProps, PageThemeConfig, ThemeOption } from "types";
import { Page } from "components/page";
import { DeepRequired } from "ts-essentials";
import { ThemeContext } from "context/theme";
import { Nav } from "components/nav";
import { Main } from "components/main";
import { Footer } from "components/footer";

const themeConfig: DeepRequired<PageThemeConfig> = {
  dark: {
    background: "bg-gray-800",
    textOnBackground: "text-white",
  },
  light: {
    background: "bg-white",
    textOnBackground: "text-gray-900",
  },
};

const themifyCodeBlocks = (theme: ThemeOption) => {
  if (theme === "dark") {
    const codeBlocks = document.querySelectorAll('code[class*="language-"]');
    codeBlocks.forEach((block) => {
      block.classList.add("dark");
    });
    const preBlocks = document.querySelectorAll('pre[class*="language-"]');
    preBlocks.forEach((block) => {
      block.classList.add("dark");
    });
  } else {
    const codeBlocks = document.querySelectorAll('code[class*="language-"]');
    codeBlocks.forEach((block) => {
      block.classList.remove("dark");
    });
    const preBlocks = document.querySelectorAll('pre[class*="language-"]');
    preBlocks.forEach((block) => {
      block.classList.remove("dark");
    });
  }
};

const BlogPostTemplate: React.FC<PageProps> = ({ data }) => {
  const { theme } = useContext(ThemeContext);

  const post = data.mdx;
  const { title, date, description, readingTime } = post.frontmatter;
  // const { previous, next } = pageContext;

  const themify = useMemo(() => themifyCodeBlocks, []);

  useEffect(() => {
    themify(theme);
  }, [theme, themify]);

  useEffect(() => {
    themify(theme);
  }, [theme, themify]);

  return (
    <Page
      title={title}
      description={description}
      themeConfig={themeConfig}
      siteTitleOverride="Michael Hueter’s Blog"
    >
      <Nav />
      <Main theme={theme}>
        <article
          className={classNames("py-5 px-0 lg:px-10 rounded-lg", {
            "text-white": theme === "dark",
            "text-gray-900": theme === "light",
          })}
        >
          <header>
            <h1 className="text-3xl">{title}</h1>
            <div className="flex mt-1">
              <small
                className={classNames("text-lg", {
                  "text-gray-700": theme === "light",
                  "text-gray-200": theme === "dark",
                })}
                style={{ paddingBottom: 1 }}
              >
                {date}
              </small>
              <small
                className={classNames("ml-3 text-lg", {
                  "text-gray-700": theme === "light",
                  "text-gray-200": theme === "dark",
                })}
                style={{ paddingBottom: 1 }}
              >
                ~ {readingTime} read
              </small>
            </div>
          </header>
          <section
            className={classNames("post-content py-10", {
              dark: theme === "dark",
            })}
          >
            <MDXRenderer>{post.body}</MDXRenderer>
          </section>
        </article>
      </Main>

      <Footer theme={theme} />
    </Page>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        readingTime
        path
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 900
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
